<!--begin::Signin header-->
<header class="signin__header">
  <app-svg-curve></app-svg-curve>
  <div class="container">
    <app-logo type="principal"></app-logo>
    <h2>Usa tu tarjeta de alimentación <strong>Pluxee</strong> en <strong>Uber Eats</strong></h2>
  </div>
</header>

<!--begin::Signin body-->
<div class="signin__body container">

    <!--begin::Form header-->
    <app-form-header
      steps="1 de 3"
      title="Iniciar sesión">
      Usa tus datos de la App u otros servicios de Pluxee. <strong>Sólo deberás ingresarlos esta vez.</strong>
    </app-form-header>

    <!--begin::Form-->
    <form
      class="form"
      [formGroup]="loginForm"
      novalidate="novalidate"
      id="login_signin_form"
      (ngSubmit)="submit()"
    >

      <!-- begin::Alert error-->
      <ng-container *ngIf="hasError">
        <div class="invalid-feedback" *ngIf="hasError">
          <svg-icon src="assets/images/icons/plx-ic-delete.svg"></svg-icon>
          Alguno de los datos es incorrecto.
        </div>
      </ng-container>
      <!-- end::Alert error-->
      <!--begin::Form group for RUT-->
      <div class="form-group">
        <label for="rut">RUT</label>
        <input
          class="form-control rut-control"
          type="text"
          name="rut"
          formControlName="rut"
          placeholder="Ingresa tu RUT asociado a tu cuenta Pluxee"
          autocomplete="new-password"
          autocorrect="off"
          autocapitalize="none"
          pattern="^[0-9kK.-]*$"
        />
        <ng-container
          [ngTemplateOutlet]="formError"
          [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'El RUT es requerido',
            control: loginForm.controls['rut']
          }"
        ></ng-container>
        <ng-container
          [ngTemplateOutlet]="formError"
          [ngTemplateOutletContext]="{
            validation: 'invalidRut',
            message: 'El RUT no es válido',
            control: loginForm.controls['rut']
          }"
        ></ng-container>
      </div>    
      
      <!--begin::Form group for Card Number-->
      <div class="form-group">
        <label for="cardNumber">Últimos 4 dígitos de la tarjeta</label>
        <input
          class="form-control card-number-control"
          type="password"
          name="cardNumber"
          formControlName="cardNumber"
          placeholder="Ingresa los últimos 4 dígitos de tu tarjeta"
          autocomplete="off"
          pattern="^[0-9]{4}$"
          maxlength="4"
          inputmode="numeric"
          oninput="this.value = this.value.replace(/[^0-9]/g, '')"
        />
        <ng-container
          [ngTemplateOutlet]="formError"
          [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'Este campo es requerido',
            control: loginForm.controls['cardNumber']
          }"
        ></ng-container>
        <ng-container
          [ngTemplateOutlet]="formError"
          [ngTemplateOutletContext]="{
            validation: 'invalidCardNumber',
            message: 'Debe contener exactamente 4 dígitos numéricos',
            control: loginForm.controls['cardNumber']
          }"
        ></ng-container>
      </div>
      
      <!-- Clave dinámica -->
      <div class="form-group">
        <label for="claveDinamica">Clave dinámica</label>
        <input
          class="form-control clave-dinamica-control"
          type="password"
          name="dynamicKey"
          formControlName="dynamicKey"
          placeholder="Ingresa la clave dinámica de 4 dígitos"
          autocomplete="off"
          pattern="^[0-9]{4}$"
          maxlength="4"
          inputmode="numeric"
          oninput="this.value = this.value.replace(/[^0-9]/g, '')"
        />
        <ng-container
          [ngTemplateOutlet]="formError"
          [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'Este campo es requerido',
            control: loginForm.controls['dynamicKey']
          }"
        ></ng-container>
        <ng-container
          [ngTemplateOutlet]="formError"
          [ngTemplateOutletContext]="{
            validation: 'invalidDynamicKey',
            message: 'Debe contener exactamente 4 dígitos numéricos',
            control: loginForm.controls['dynamicKey']
          }"
        ></ng-container>
      </div>
      <!--begin::Action-->
      <div class="form__action">
        <button app-button type="submit" [loading]="loading" [disabled]="loginForm.invalid">
          Continuar
        </button>
      </div>
      <!--end::Action-->
    </form>
    <!--end::Form-->
</div>
<!--end::Signin-->

<ng-template
  #formError
  let-control="control"
  let-message="message"
  let-validation="validation"
>
  <ng-container
    *ngIf="control.hasError(validation) && (control.dirty || control.touched)"
  >
    <div class="invalid-feedback">
      <svg-icon src="assets/images/icons/plx-ic-delete.svg"></svg-icon>
      {{ message }}
    </div>
  </ng-container>
</ng-template>

import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-junaeb-dynamic-key',
  templateUrl: './junaeb-dynamic-key.html',
  styleUrls: ['./junaeb-dynamic-key.scss']
})
export class JunaebDynamicKeyComponent implements OnInit, OnDestroy, AfterViewInit {

  defaultAuth: any = {
    rut: '', 
    serialNumberIdentification: '', 
    purchasePin: '',
  };
  loginForm: FormGroup = this.initForm();
  loading: boolean = false;
  hasError: boolean = false;
  transactionToken: string = '';
  balance: number = 0;
  codeGenerated: String = '';
  hiddenContinueButton = false;
  isModalOpen = false;
  token: String = '';

  private unsubscribe: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private titleService: Title
  ) {

  }
  ngAfterViewInit(): void {
    this.authService.getTokenFromAuthServices().subscribe( getToken => { 
      this.token = getToken.body.access_token;
    });
  }

  ngOnInit(): void {
    this.transactionToken = this.route.snapshot.queryParams['token'.toString()];
    this.initForm();
    this.titleService.setTitle('Pluxee');
  }
  

  get f() {
    return this.loginForm.controls;
  }

  initForm(): FormGroup {
    return this.fb.group({
        rut: [
            '',
            Validators.compose([
                Validators.required, 
                this.rutValidator(),
            ]),
        ],
        serialNumberIdentification: [
            '',
            Validators.compose([
                Validators.required,
                Validators.minLength(9),
                Validators.maxLength(9)
            ]),
        ],
        purchasePin: [
            '',
            Validators.compose([
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(4),
                this.fourDigitsValidator('invalidPurchaseKey'),
            ]),
        ],
    });
  }
  rutValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const rut = control.value;
        if (!rut || rut.trim() === '') {
            return { required: true }; 
        }
        if (!this.isValidRut(rut)) {
            return { invalidRut: true };
        }
        return null; 
    };
  }
  isValidRut(rut: string): boolean {
    rut = rut.replace(/\./g, '').replace(/-/g, '');

    if (rut.length < 2) {
        return false;
    }

    const rutBody = rut.slice(0, -1);
    const dv = rut.slice(-1).toUpperCase();

    if (!/^\d+$/.test(rutBody)) {
        return false;
    }

    let sum = 0;
    let multiplier = 2;

    for (let i = rutBody.length - 1; i >= 0; i--) {
        sum += parseInt(rutBody.charAt(i), 10) * multiplier;
        multiplier = multiplier === 7 ? 2 : multiplier + 1;
    }

    const dvExpected = 11 - (sum % 11);
    const dvCalculated = dvExpected === 11 ? '0' : dvExpected === 10 ? 'K' : dvExpected.toString();

    return dv === dvCalculated;
  }


  fourDigitsValidator(errorKey: string) {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const value = control.value;
        const isValid = /^[0-9]{4}$/.test(value);
        return isValid ? null : { [errorKey]: true };
    };
  }

  removeRutFormat(rut: string): string {
    return rut.replace(/\./g, '').replace(/-/g, '');
  }

  submit() {
    this.loading = true;
    this.hasError = false;

    const body: any = {
      rut: this.f.rut.value,
      serialNumberIdentification: this.f.serialNumberIdentification.value, 
      purchasePin: this.f.purchasePin.value
    };

    this.authService.postGenerateDynamicKey(this.token,body).subscribe(data => {
      if(data.body.status != true || data.body.status != undefined || data.body.status != null){
        this.isModalOpen = true;
      }else{
        this.loading = false;
        this.balance = data.body.balance;
        this.codeGenerated = data.body.codeGenerated;
        this.hiddenContinueButton = true;
      }
    },error => {
      console.log('[ERROR] al generar clave dinamica', error);
      this.router.navigateByUrl('/junaeb-dynamic-key');
    });
  }

  generateDinamycKey(){
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
    this.router.navigateByUrl('/junaeb-dynamic-key');
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}

<!-- Header -->
<header class="card-selection__header">
  <app-svg-curve></app-svg-curve>
  <div class="container">
    <app-logo></app-logo>
  </div>
</header>

<!-- Selección de tarjeta -->
<div class="card-selection__body container" *ngIf="step === 0">
  <app-form-header
    steps="2 de 3"
    title="Seleccionar tarjeta">
    Elige la tarjeta Pluxee que usarás para pagar  
      <!-- begin::Alert error-->
      <ng-container *ngIf="hasError">
        <div class="invalid-feedback" *ngIf="hasError">
          <svg-icon src="assets/images/icons/plx-ic-delete.svg"></svg-icon>
          Usuario no cuenta con tarjetas habilitadas para continuar.
        </div>
      </ng-container>
  </app-form-header>
  <app-card-selector
    [cards]="cards"
    (cardChange)="updateCardPan($event)">
  </app-card-selector>
  <div class="form-group">
    <a [routerLink]="['/card-help']" class="btn btn--kind-secondary btn--aspect-text btn--icon btn--icon-position-left">
      <svg-icon src="assets/images/icons/plx-ic-question.svg"></svg-icon><span class="btn__label">¿No conoces el número de tu tarjeta?</span>
    </a>
  </div>
  <div class="form__action">
    <button app-button [disabled]="!f.cardNumber.valid" [loading]="loading" (click)="setStep(1)">
      Continuar
    </button>
  </div>
</div>

<!-- Ingreso de código pin -->
<div class="card-selection__body container" *ngIf="step === 1 && f.cardNumber.valid">
  <app-form-header
    steps="3 de 3"
    title="Ingresar PIN de compra">
    Escribe los 4 dígitos con los que confirmas tu compra en la App Pluxee
  </app-form-header>
  <code-input #codeInput (codeChanged)="updatePincode($event)" [code]="f.pincode.value" [codeLength]="4"></code-input>
  <div class="invalid-feedback" *ngIf="!f.pincode.valid && validated && !isValid">
    <svg-icon src="assets/images/icons/plx-ic-delete.svg"></svg-icon>
    Pin Incorrecto
  </div>
  <div class="form__action">
    <button app-button [disabled]="!f.pincode.valid" [loading]="loading" (click)="submit()">
      Continuar
    </button>
  </div>
</div>

import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment as env } from 'src/environments/environment';

const BASE_URL = env.apiUrl;

// const BASE_URL_AUTH = 'https://auth.servicios.pluxee.cl/get-token';
const BASE_URL_AUTH = 'https://api.servicios.dev.pluxee.cl/auth-server-ext/oauth/token';

const BASE_URL_GENERATE_DYNAMIC_KEY = '/user/generate-dynamic-key';
@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {

  public currentUser$!: Observable<User>;
  protected currentUserSubject!: BehaviorSubject<User>;

  private unsubscribe: Subscription[] = [];
  private authLocalStorage = `sodexo-uber-payment`;

  hasError: boolean = false;
  returnUrl: string = '';

  constructor(
    private router: Router,
    private http: HttpClient
  ) {

  }

  get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: User) {
    this.currentUserSubject.next(user);
  }

  login(rut: string, cardNumber: string, dynamicKey: string, transactionToken: string):Observable<User> {
    const auth = this.http.post<User>(BASE_URL + '/user/login', { rut, cardNumber, dynamicKey, transactionToken })
    return auth    
  }

  logout() {
    localStorage.removeItem(this.authLocalStorage);
    this.router.navigate(['/signin'], {
      queryParams: {},
    });
  }

  getAuthFromLocalStorage() {
    try {
      const auth = localStorage.getItem(this.authLocalStorage);
      if (typeof auth === 'string') {
        return JSON.parse(auth);
      }
      return auth;
    } catch (error) {
      return undefined;
    }
  }

  setAuthFromLocalStorage(auth: any): boolean {
    if (auth) {
      localStorage.setItem(this.authLocalStorage, auth);
      return true;
    }
    return false;
  }

  getTokenFromAuthServices(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'basic UjJkcHhRM3ZQcnRmZ0Y3MjpmRHc3TXBrazVjekhOdVNSdG1oR21BR0w0MkNheFFCOQ=='
    });
    const body = new HttpParams().set('grant_type', 'client_credentials')
    return this.http.post<any>(BASE_URL_AUTH, body, { headers, observe: 'response'});
  }

  postGenerateDynamicKey(token: any, body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
    return this.http.post<any>(BASE_URL + BASE_URL_GENERATE_DYNAMIC_KEY, body, { headers, observe: 'response'});
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}

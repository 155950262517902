import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Injectable({ providedIn: 'root' })

export class UserService {

    currentUser: any;
    constructor(private apiService: ApiService, private authenticationService: AuthService) {
        this.currentUser = this.authenticationService.getAuthFromLocalStorage();
    }

    getCards(rut: string) {
        return this.apiService.postFindActiveCardBeneficiary('/user/findActiveCardsByBeneficiary',this.currentUser.transactionToken, rut);
    }

    pinValidation(pin:string, rut: string) {
        return this.apiService.postPinValidation('/user/validatePin', pin, this.currentUser.transactionToken, rut);
    }

    setCard(pan: number, rut: string) {
        return this.apiService.postconfirmCardEnrollment('/user/confirmCardEnrollment', pan, rut, this.currentUser.transactionToken, );
    }
}

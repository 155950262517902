import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  code = this.route.snapshot.paramMap.get('id');

  constructor(
    private route: ActivatedRoute, 
    private authService: AuthService) { }

  ngOnInit(): void {
  }

  close() {
    this.authService.logout();
  }

  goToUber() {
     this.authService.logout();
     window.location.href = 'http://urldeuber.com?code=' + this.code
  }
}

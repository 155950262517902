import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { AbstractControl } from '@angular/forms';



@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit, OnDestroy {

  defaultAuth: any = {
    rut: '', 
    cardNumber: '', 
    dynamicKey: '',
  };
  loginForm: FormGroup = this.initForm();
  loading: boolean = false;
  hasError: boolean = false;
  transactionToken: string = '';

  private unsubscribe: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {

  }

  ngOnInit(): void {
    this.transactionToken = this.route.snapshot.queryParams['token'.toString()];
    this.initForm();
  }

  get f() {
    return this.loginForm.controls;
  }

  initForm(): FormGroup {
    return this.fb.group({
        rut: [
            '',
            Validators.compose([
                Validators.required, 
                this.rutValidator(),
            ]),
        ],
        cardNumber: [
            '',
            Validators.compose([
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(4),
                this.fourDigitsValidator('invalidCardNumber'),
            ]),
        ],
        dynamicKey: [
            '',
            Validators.compose([
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(4),
                this.fourDigitsValidator('invalidDynamicKey'),
            ]),
        ],
    });
  }
  rutValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const rut = control.value;
        if (!rut || rut.trim() === '') {
            return { required: true }; 
        }
        if (!this.isValidRut(rut)) {
            return { invalidRut: true };
        }
        return null; 
    };
  }
  isValidRut(rut: string): boolean {
    rut = rut.replace(/\./g, '').replace(/-/g, '');

    if (rut.length < 2) {
        return false;
    }

    const rutBody = rut.slice(0, -1);
    const dv = rut.slice(-1).toUpperCase();

    if (!/^\d+$/.test(rutBody)) {
        return false;
    }

    let sum = 0;
    let multiplier = 2;

    for (let i = rutBody.length - 1; i >= 0; i--) {
        sum += parseInt(rutBody.charAt(i), 10) * multiplier;
        multiplier = multiplier === 7 ? 2 : multiplier + 1;
    }

    const dvExpected = 11 - (sum % 11);
    const dvCalculated = dvExpected === 11 ? '0' : dvExpected === 10 ? 'K' : dvExpected.toString();

    return dv === dvCalculated;
  }


  fourDigitsValidator(errorKey: string) {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const value = control.value;
        const isValid = /^[0-9]{4}$/.test(value);
        return isValid ? null : { [errorKey]: true };
    };
  }
  submit() {
    this.loading = true;
    this.hasError = false;
    let loginSubscr: any;
    
         loginSubscr = this.authService.login(this.f.rut.value, this.f.cardNumber.value,this.f.dynamicKey.value , this.transactionToken).subscribe(
          user => {

            if(user.ok){

              this.loading = false;
              this.authService.setAuthFromLocalStorage(JSON.stringify(user.userInfo));
              this.router.navigate(['card-selection']);
  
            }
            else{ 
            
              this.hasError = true;
              this.unsubscribe.push(loginSubscr);
              this.loading = false;
            }

          },
          err => {
            this.hasError = true;
            this.unsubscribe.push(loginSubscr);
            this.loading = false;
      });   
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
